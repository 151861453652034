import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database'; 

const firebaseConfig = {
  apiKey: "AIzaSyB47GMbBnvC4kHO4e1_MhY-FPKsd-lqL-k",
  authDomain: "defender-media-crm.firebaseapp.com",
  databaseURL: "https://defender-media-crm-default-rtdb.firebaseio.com",
  projectId: "defender-media-crm",
  storageBucket: "defender-media-crm.appspot.com",
  messagingSenderId: "508132036738",
  appId: "1:508132036738:web:e72fc631ea6929a66c649b"
};

// Inicializa Firebase
firebase.initializeApp(firebaseConfig);

// Obtiene la instancia de Firestore
const firestore = firebase.firestore();

// Obtiene la instancia de Storage
const storage = firebase.storage();

// Obtiene la instancia de Realtime Database
const database = firebase.database();

export { firestore, storage, database };
