import React, {useEffect, useRef, useState} from "react";
import "./App.css";
import {Button, Layout} from "antd";
import { database } from "./firebase";
import "firebase/database";

import SignatureCanvas from "react-signature-canvas";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";  // Importa los estilos de Swiper

// Si usas módulos adicionales como la navegación
import SwiperCore, { Navigation } from 'swiper/core';



const Estimate = () => {

	SwiperCore.use([Navigation]);

    const { id } = useParams();
	const [state, setState] = useState({
		pdfData: null,
		generatedPdf: "",
		signature: "",
		loading: false,
		btndisable:true,
	});
    console.log(id)
	const urlParams = new URLSearchParams(window.location.search);
	const reloaded = urlParams.get("reloaded");


	const SignatureRef = useRef();
	const path = window.location.pathname;
	useEffect(() => {
		if(id === "/"){
			window.location.replace("https://defendermedia.us/");
		}else{
			SignatureRef.current.off()
			onComponentMountAsync(id);
		}
	}, [id,state.pdfData]);
	const onComponentMountAsync = async (id) => {
		const dbRef = database.ref();
		let btnstate = true
		dbRef.child("contracts/"+id).once('value').then((snapshot) => {
			console.log(snapshot.val().EstimateSignature === undefined || snapshot.val().EstimateSignature === "")
		  if (snapshot.exists()) {
			if(snapshot.val().EstimateSignature === undefined || snapshot.val().EstimateSignature === ""){
				SignatureRef.current.on()
				btnstate = false
			}else{
				console.log("el estimado esta firmado")
				Swal.fire({
					title: reloaded? "success!" : "ATTENTION!",
					text: reloaded? "Estimate signed successfully!" : "The Contract Is Signed",
					icon: reloaded? "success" : "warning"
				  });
			}
			setState({...state, pdfData:snapshot.val(), btndisable:btnstate})
		  } else {
			console.log("No data available");
		  }
		}).catch((error) => {
		  console.error(error);
		});
	};

	const uploadPdf = async () => {
		//console.log(SignatureRef.current.getTrimmedCanvas().toDataURL("image/png"))
		setState({...state, loading: true});
		if (SignatureRef.current.isEmpty()) {
			setState({...state, loading: false});
			return console.log("empty");
		}

		// Extraer email y cell del registro antes de actualizarlo
		const contractRef = database.ref("contracts/" + id);
		const contractSnapshot = await contractRef.once('value');
		const contractData = contractSnapshot.val();

		// Determinar el valor de "type"
		let type = "";
		const email = contractData?.email || "";
		const cell = contractData?.cell || "";

		if (email && cell) {
			type = "emailsms";
		} else if (email) {
			type = "Email";
		} else if (cell) {
			type = "SMS";
		}

		await database.ref("contracts/" + id).update({
			ContactSignature: SignatureRef.current.getTrimmedCanvas().toDataURL("image/png"),
		}).then(async ()=>{
			const response = await fetch(`https://us-central1-defender-media-crm.cloudfunctions.net/generateContact?type=${type}&ContractID=${id}`, {
				method: "GET",
			})
			console.log(response)
			if (response.ok) {
				const result = await response.json();
				setState({...state, loading: false});
				console.log('Function executed successfully:', result);
				window.location.replace(`${window.location.href}?reloaded=true`);

			} else {
				setState({...state, loading: false});
				console.error('Error executing function:', response.statusText);
				Swal.fire({
					title: "Error!",
					text: "The Estimate Could Not Be Signed",
					icon: "error"
				  });
				window.location.replace(`${window.location.href}`);
			}
		})
	};

	return (
	    <Layout style={{ minHeight: '100vh', backgroundColor:"#ededed", width: '100%' }}>
		<div style={{ marginBottom: "20px", width: "100%" }}>
			<Swiper
			navigation
			style={{ width: "100%", height: "100%" }}
			>
			{state.pdfData?.ContractimageURL &&
				state.pdfData.ContractimageURL.map((imageURL, index) => (
				<SwiperSlide key={index}>
					<img
					alt={`Estimate Slide ${index + 1}`}
					src={imageURL}
					style={{
						width: "100%",
						height:
						state.pdfData?.ContactSignature === undefined ||
						state.pdfData?.ContactSignature === ""
							? "65vh"
							: "100vh",
						border: "1px solid black",
						objectFit: "contain",
					}}
					/>
				</SwiperSlide>
				))}
			</Swiper>
		</div>


        {
        state.pdfData?.ContactSignature === undefined || state.pdfData?.ContactSignature === "" ? 
		<>
			<div style={{ marginBottom: '10px', textAlign: 'center', fontSize: '24px', width: "100%" }}>
					<SignatureCanvas
						backgroundColor={"#ffff"}
						penColor={"#000"}
						
						ref={SignatureRef}
						canvasProps={{
							width: "300vw",
							height: 70,
							className: "sigCanvas",
						}}
					/>
			</div>

			<div style={{ marginBottom: '10px', textAlign: 'center', fontSize: '18px' }}>
			Sign here
			</div>

			<div style={{ textAlign: 'center' }}>
			<Button 
				disabled={state.btndisable}
				type="primary" size="large"
				loading={state.loading}
				onClick={() => uploadPdf()}
				>
					Submit Signature
			</Button>
			</div>
			
		</>
		:null
		}

	
    </Layout>
	);
}

export default Estimate;
